import { useEffect, useState } from 'react';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import classes from './Presupuesto.module.css';
import apiService from '../utils/apiService';

import Input from '../components/Input';
import Button from '../components/Button';
import Loader from "../components/Loader";

import Select from 'react-select';

import Modal from '../components/Modal';
import ErrorMessage from '../components/ErrorMessage';

const dataStructure = {
    cliente : '',
    valor_operacion : '',
    operacion : '',
    porcentaje_ISAI : '3%',
    certificado_planos : '$203.00',
    derecho_registro_municipal: '$1,125.00',
    gastos_escrituracion: '',
    honorarios: '',
    campos_personalizados: []
};

export default function Presupuesto() {
    const [optionsOperacion, setOptionsOperacion] = useState([]);
    const [data, setData] = useState(dataStructure);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [customFields, setCustomFields] = useState([]);

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const handleSelect = (valueDef, nameDef) => {
        const value = valueDef.value;
        const name = nameDef;

        setData((prev) => ({
            ...prev,
            [name] : value
        }));
    };

    const formatValue = (inputValue) => {
        // Remove any non-numeric characters except for periods
        let numericValue = inputValue.replace(/[^0-9.]/g, '');

        // Split the value into integer and decimal parts
        let [integer, decimal] = numericValue.split('.');

        // Ensure the integer part is not empty
        if (!integer) {
          integer = '0';
        }

        // Truncate decimal part to two digits
        if (decimal) {
          decimal = decimal.slice(0, 2);
        }

        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Join the integer and decimal parts
        numericValue = decimal !== undefined ? `${integer}.${decimal}` : integer;

        return numericValue;
      };

    const handleChange = (e) => {
        const { name, value } = e.target;

        let formattedValue;
        if (value.charAt(0) !== '$') {
          formattedValue = `$${formatValue(value)}`;
        } else {
          formattedValue = `$${formatValue(value.slice(1))}`;
        }

        setData((prev) => ({
            ...prev,
            [name] : formattedValue
        }));
    };

    const formatPercentage = (inputValue) => {
        // Remove any non-numeric characters
        let numericValue = inputValue.replace(/[^0-9]/g, '');
    
        // Convert to number and ensure it's between 0 and 100
        let numberValue = parseInt(numericValue, 10);
        if(isNaN(numberValue)){
            numberValue = 0;
        }
        else if (numberValue < 0) {
            numberValue = 0;
        } else if (numberValue > 100) {
            numberValue = 100;
        }
    
        return `${numberValue}%`;
    };

    const handleChangePercentage = (e) => {
        const { name, value } = e.target;

        let formattedValue;
        if (value.charAt(value.length - 1) !== '%') {
            // If the value doesn't end with '%', format as percentage
            formattedValue = formatPercentage(value);
        } else {
            // If the value ends with '%', just format the number part
            formattedValue = formatPercentage(value.slice(0, -1));
        }

        setData((prev) => ({
            ...prev,
            [name] : formattedValue
        }));
    };


    const handleChangeText = (e) => {
        
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name] : value
        }));
    };

    const mapOperacionOptions = async () => {
        const operaciones = await getOperations();

        if(operaciones === undefined) return;

        const mappedData = operaciones.map(operacion => {
            return {
                id: operacion.id_operacion,
                label: operacion.operacion,
                value: operacion.operacion,
            }
        })
        mappedData.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            } if (a.label > b.label) {
                return 1;
            }
            return 0;
        })
        setOptionsOperacion(mappedData)
    }

    const getOperations = async () => {
        try {
            const response = await apiService.get('/operaciones/', {});
            return response.data
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message || 'Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al obtener las operaciones, código: '+errorCode, message: errorMessage});
        }
    };

    const addCustomField = () => {
        setCustomFields(prev => [...prev, {
            nombre: '',
            valor: '$0.00'
        }]);
    };

    const removeCustomField = (index) => {
        setCustomFields(prev => prev.filter((_, i) => i !== index));
    };

    const handleCustomFieldChange = (index, field, value) => {
        setCustomFields(prev => {
            const newFields = [...prev];
            if (field === 'valor') {
                // Format currency value
                let formattedValue;
                if (value.charAt(0) !== '$') {
                    formattedValue = `$${formatValue(value)}`;
                } else {
                    formattedValue = `$${formatValue(value.slice(1))}`;
                }
                newFields[index] = {
                    ...newFields[index],
                    [field]: formattedValue
                };
            } else {
                newFields[index] = {
                    ...newFields[index],
                    [field]: value
                };
            }
            return newFields;
        });
    };

    const sendData = async () => {
        setLoading(() => true);

        const formattedData = {
            cliente: data.cliente,
            valor_operacion: parseFloat(data.valor_operacion.replaceAll(",", "").slice(1)) || 0,
            operacion: data.operacion,
            gastos_escrituracion: parseFloat(data.gastos_escrituracion.slice(1).replaceAll(",", "")) || 0,
            honorarios: parseFloat(data.honorarios.slice(1).replaceAll(",", "")) || 0,
            isai: data.porcentaje_ISAI,
            isai_valor: ((parseFloat(data.valor_operacion.replaceAll(",", "").slice(1)) || 0) * (parseFloat(data.porcentaje_ISAI) / 100)),
            servicios_catastrales: (
                ((parseFloat(data.valor_operacion.replaceAll(",", "").slice(1)) || 0) * 0.0018)
                +
                (parseFloat(data.certificado_planos.slice(1).replaceAll(",", "")) || 0)
                +
                (parseFloat(data.derecho_registro_municipal.slice(1).replaceAll(",", "")) || 0)
            ),
            campos_personalizados: customFields.map(field => ({
                nombre: field.nombre,
                valor: field.valor ? parseFloat(field.valor.slice(1).replaceAll(",", "")) || 0 : 0
            }))
        };

        const responseData = await postFormat(formattedData);

        if(responseData === undefined){
            setLoading(() => false);
            return;
        };

        const blob = new Blob([responseData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `presupuesto_${data.cliente}.xlsx`);  // Replace with the desired file name
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };

    const postFormat = async (data) => {
        try {
            const response = await apiService.post('/populate-excel/', data, {
                responseType: 'blob',
            });

            if (response.status === 200) {
                setLoading(() => false);
                return response.data;
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener el archivo, código: '+errorCode, message: errorMessage});
        }
    }

    useEffect(() => {
        mapOperacionOptions()
    }, []);

    return(
        <div className={classes.container}>
            <h1>
                Presupuesto
            </h1>
            <hr />
            <p style={{marginBottom: '40px'}}>
                Crea el archivo de presupuesto llenando los siguientes datos, al dar click al botón se descargará automáticamente.
            </p>

            <div className={classes.buttonContainer}>
                <Button action={sendData}>Obtener formato</Button>
            </div>
            <div className={classes.innerContainer}>
                <div className={classes.inputContainer}>
                    <Input label={"Nombre cliente"} name='cliente' type='text'
                        value={data.cliente}
                        onChange={handleChangeText}
                        placeholder='Agregar nombre del cliente'
                    />

                    <Input label={"Valor operación o catastral"} name='valor_operacion' type='text'
                        value={data.valor_operacion}
                        onChange={handleChange}
                        placeholder='Agregar valor'
                    />

                    {
                    //Cliente
                    }
                    <span>
                        <label className={classes.labelText}>Operación
                            <Select name='operacion' 
                            className={classes.selectDG} 
                            onChange={(choice) => handleSelect(choice, 'operacion')}
                            // value={optionsOperacion.find((option) => option.value === data.operacion) || ''}
                            // value={{label: data.operacion || '', value: data.operacion || ''}}
                            options={optionsOperacion}
                            classNames={customClassNames}
                            theme={customSelectTheme}
                            />
                        </label>
                    </span>

                    <Input label={"Porcentaje ISAI"} name='porcentaje_ISAI' type='text'
                        value={data.porcentaje_ISAI}
                        onChange={handleChangePercentage}
                        placeholder='Agregar porcentaje ISAI'
                    />

                    <Input label={"Valor ISAI"} name='valor_ISAI' type='text'
                        value={`$${((parseFloat(data.valor_operacion.replaceAll(",", "").slice(1)) || 0) * (parseFloat(data.porcentaje_ISAI) / 100)).toFixed(2)}`}
                        readOnly
                        placeholder='Agregar valor'
                    />
                </div>

                <div className={classes.inputContainer}>
                    <Input label={"Gastos escrituración"} name='gastos_escrituracion' type='text'
                        value={data.gastos_escrituracion}
                        onChange={handleChange}
                        placeholder='Agregar'
                    />

                    <Input label={"Honorarios"} name='honorarios' type='text'
                        value={data.honorarios}
                        onChange={handleChange}
                        placeholder='Agregar'
                    />

                    <Input label={"Certificado de planos"} name='certificado_planos' type='text'
                        value={data.certificado_planos}
                        onChange={handleChange}
                        placeholder='Agregar valor'
                    />

                    <Input label={"Tarifa 1.8 al millar"} name='tarifa_18millar' type='text'
                        value={`$${((parseFloat(data.valor_operacion.replaceAll(",", "").slice(1)) || 0) * 0.0018).toFixed(2)}`}
                        readOnly
                        placeholder='Agregar valor'
                    />

                    <Input label={"Derecho de registro municipal"} name='derecho_registro_municipal' type='text'
                        value={data.derecho_registro_municipal}
                        onChange={handleChange}
                        placeholder='Agregar valor'
                    />

                </div>
            </div>
            <div className={classes.customFieldsContainer}>
                <h3>Campos Personalizados</h3>
                <div className={classes.addFieldButtonContainer}>
                    <Button action={addCustomField}>
                        Agregar Nuevo Campo
                    </Button>
                </div>
                
                {customFields.map((field, index) => (
                    <div key={index} className={classes.customFieldRow}>
                        <Input 
                            label={`Campo ${index + 1}`}
                            value={field.nombre}
                            onChange={(e) => handleCustomFieldChange(index, 'nombre', e.target.value)}
                            placeholder='Nombre del campo personalizado'
                        />
                        <Input 
                            label={`Valor`}
                            value={field.valor}
                            onChange={(e) => handleCustomFieldChange(index, 'valor', e.target.value)}
                            placeholder='Agregar valor'
                            type='text'
                        />
                        <Button action={() => removeCustomField(index)}>
                            Eliminar
                        </Button>
                    </div>
                ))}
            </div>

            {
                loading && <div className={classes.loaderContainer}>
                    <Loader />
                </div>
            }

            {
                errorMessage && <Modal handleClose={handleClose}>
                    <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
                </Modal>
            }

        </div>
    );
};
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classes from './Tooltip.module.css';

const Tooltip = ({ children, text }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const triggerRef = useRef(null);

    const updatePosition = () => {
        if (triggerRef.current && isVisible) {
            const rect = triggerRef.current.getBoundingClientRect();
            setPosition({
                top: rect.top - 10,  // 10px above the trigger element
                left: rect.left + (rect.width / 2)
            });
        }
    };

    useEffect(() => {
        if (isVisible) {
            updatePosition();
            window.addEventListener('scroll', updatePosition);
            window.addEventListener('resize', updatePosition);
        }
        return () => {
            window.removeEventListener('scroll', updatePosition);
            window.removeEventListener('resize', updatePosition);
        };
    }, [isVisible]);

    const tooltipContent = isVisible && ReactDOM.createPortal(
        <div 
            className={classes.tooltipContent}
            style={{
                top: `${position.top}px`,
                left: `${position.left}px`,
            }}
        >
            {text}
            <div className={classes.arrow} />
        </div>,
        document.body
    );

    return (
        <div 
            ref={triggerRef}
            className={classes.tooltipTrigger}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            {children}
            {tooltipContent}
        </div>
    );
};

export default Tooltip; 
import { useEffect, useState } from "react";
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';

import {InputFile} from '../components/InputFile';

import mammoth from 'mammoth';
import Select from 'react-select';
import apiService from '../utils/apiService';
import Button from "../components/Button";

import Loader from "../components/Loader";
import CollapseMenu from "../components/CollapseMenu";

import classes from './Formatos.module.css';
import NoInfo from "../components/NoInfo";
import Input from "../components/Input";
import Dialog from "../components/Dialog";
import TagGenerator from "../components/TagGenerator";

const TEMPLATE_TAGS = {
    BASIC: [
        { label: 'Operación', value: '{{ operacion }}' },
        { label: 'Valor de Operación', value: '{{ valor_operacion }}' },
    ],
    CLIENT: [
        { label: 'Nombre Completo', value: '.nombre' },
        { label: 'Datos Generales', value: '.datos' },
        { label: 'CURP', value: '.curp' },
        { label: 'RFC', value: '.rfc' },
        { label: 'Domicilio', value: '.domicilio' },
        { label: 'Edad', value: '.edad' },
        { label: 'Nacionalidad', value: '.nacionalidad' },
        { label: 'Estado Civil', value: '.estado_civil' },
        { label: 'Profesión', value: '.profesion' },
        { label: 'Ocupación', value: '.ocupacion' },
        { label: 'INE', value: '.ine' }
    ]
};

const keywords = ['operacion', 'valor_operacion'];

const tagsData = {
    person : '',
    quantity: 0,
};

export default function Formatos(){
    // State for file handling
    const [fileState, setFileState] = useState('initial'); // initial, uploaded, processing
    const [fileExtracted, setFileExtracted] = useState(undefined);
    const [extractedTexts, setExtractedTexts] = useState([]);

    // State for expediente and clients
    const [expedientes, setExpedientes] = useState([]);
    const [optionsExpediente, setOptionsExpediente] = useState([]);
    const [optionsClientes, setOptionsClientes] = useState([]);
    const [selectedExpediente, setSelectedExpediente] = useState(undefined);
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedClientIDs, setSelectedClientIDs] = useState({});

    // State for tag generator dialog
    const [isTagGeneratorOpen, setIsTagGeneratorOpen] = useState(false);
    const [tagConfig, setTagConfig] = useState({
        prefix: '',
        quantity: 1,
        selectedTag: ''
    });

    // State for download and loading
    const [download, setDownload] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [tags, setTags] = useState(tagsData);
    const [createTags, setCreateTags] = useState(false);

    const transformDatosGenerales = (data) => ({
        expediente: data.id_expediente,
        titulo_expediente: data.titulo_expediente,
        valor_operacion: data.valor_operacion,
        operacion: data.operacion,
    });
    
    const handleSelectExpediente = async (choice) => {
        // Find the expediente using the correct ID
        const selectedExp = expedientes.find(ex => ex.id === choice.value);
        setSelectedExpediente(selectedExp);
        
        setLoading(true);
        try {
            await getClientesAndDocs(choice.value);
        } catch (error) {
            console.error('Error loading clients:', error);
            alert('Error al cargar los clientes');
        }
        setLoading(false);
    };

    const handleSelectClient = (choice, field) => {
        const obj = optionsClientes.find((cliente) => cliente.value === choice.value);

        setSelectedClient((prev) => ({
            ...prev,
            [field]: choice.value
        }));

        setSelectedClientIDs((prev) => ({
            ...prev,
            [field]: obj.id
        }));
    };

    const transformExpediente = (dbData) => {
        return dbData.map(data => ({
            id: data.id_expediente,
            titulo_expediente: data.titulo_expediente,
            valor_operacion: data.valor_operacion,
            operacion: data.operacion
        }));
    };

    const createOptionsExpediente = (expedientes) => {
        return expedientes.map((expediente) => ({
            value: expediente.id,
            label: expediente.titulo_expediente
        }));
    };

    const mapClientOptions = (clientes) => {
        return clientes
            .map(cliente => ({
                id: cliente.id_cliente,
                label: `${cliente.nombre} ${cliente.apellido_paterno} ${cliente.apellido_materno}`,
                value: `${cliente.nombre} ${cliente.apellido_paterno} ${cliente.apellido_materno}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    };

    const getClientesAndDocs = async (expedienteId) => {
        try {
            const response = await apiService.get(`/expedientes/${expedienteId}/clientes/`);
            const mappedData = mapClientOptions(response.data.clientes);
            setOptionsClientes(mappedData);
            
            // Reset selected clients when changing expediente
            setSelectedClient({});
            setSelectedClientIDs({});
        } catch (error) {
            console.error('Error getting clients:', error);
            throw error;
        }
    };

    const getExpedientes = async () => {
        try {
            const response = await apiService.get('/expedientes/', {});
            console.log(response.data);
            setOptionsExpediente(createOptionsExpediente(transformExpediente(response.data)));
            setLoading(() => false);
            return response.data.map(transformDatosGenerales);
        } catch (error) {
            console.error('There was an error getting the info!', error);
        }
    };

    const handleFile = async (file) => {
        try {
            setLoading(true);
            setFileState('processing');
            
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const result = await mammoth.extractRawText({ arrayBuffer: e.target.result });
                    const textContent = result.value;

                    // Extract tags
                    const tagRegex = /{{[\s]*([^}]+)[\s]*}}|<([^>]+)>/g;
                    let matches = [];
                    let match;

                    while ((match = tagRegex.exec(textContent)) !== null) {
                        matches.push(match[1] || match[2]);
                    }

                    const extractedTags = matches
                        .map(tag => tag.trim())
                        .filter(tag => {
                            if (keywords.includes(tag)) return false;
                            return /^[a-zA-Z]+\d+/.test(tag.split('.')[0]);
                        })
                        .map(tag => tag.split('.')[0])
                        .filter((tag, index, self) => self.indexOf(tag) === index);

                    if (extractedTags.length === 0) {
                        alert('No se encontraron etiquetas en el documento.');
                        setFileState('initial');
                        return;
                    }

                    setExtractedTexts(extractedTags);
                    setFileExtracted(file);
                    setFileState('uploaded');
                    
                    // Get expedientes
                    const response = await apiService.get('/expedientes/');
                    const expedientesData = transformExpediente(response.data);
                    setExpedientes(expedientesData);
                    setOptionsExpediente(createOptionsExpediente(expedientesData));
                    
                } catch (error) {
                    console.error('Error processing document:', error);
                    alert('Error al procesar el documento.');
                    setFileState('initial');
                }
                setLoading(false);
            };

            reader.readAsArrayBuffer(file);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
            alert('Error al procesar el archivo');
            setFileState('initial');
        }
    };

    const handleSendingFormat = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', fileExtracted);
            
            const data = {
                expediente: selectedExpediente.id,
                titulo: selectedExpediente.titulo_expediente,
                ...selectedClientIDs
            };
            
            // Properly stringify the data before appending
            formData.append('data', JSON.stringify(data));

            console.log('Sending data:', data); // Debug log
            console.log('FormData entries:', [...formData.entries()]); // Debug log

            const response = await apiService.post('/process-document/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                },
                responseType: 'blob'
            });

            if (!response.data) {
                throw new Error('No data received from server');
            }

            // Check if the response is an error message
            if (response.data.type === 'application/json') {
                const reader = new FileReader();
                reader.onload = () => {
                    const error = JSON.parse(reader.result);
                    throw new Error(error.error || 'Error processing document');
                };
                reader.readAsText(response.data);
                return;
            }

            const blob = new Blob([response.data], { 
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
            });
            const url = URL.createObjectURL(blob);
            setDownload(url);
            
        } catch (error) {
            console.error('Error sending format:', error);
            alert(`Error al procesar el formato: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            // Optional: Add some visual feedback
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const handleTagConfigChange = (e) => {
        const { name, value } = e.target;
        setTagConfig(prev => ({
            ...prev,
            [name]: name === 'quantity' ? Math.min(Math.max(1, parseInt(value) || 1), 10) : value
        }));
    };

    const generateTags = () => {
        const { prefix, quantity, selectedTag } = tagConfig;
        if (!prefix || !selectedTag) return [];

        return Array.from({ length: quantity }, (_, i) => ({
            label: `${prefix}${i + 1}${selectedTag}`,
            value: `{{ ${prefix}${i + 1}${selectedTag} }}`
        }));
    };

    return (
        <div className={classes.container}>
            <h1>
                Formatos
            </h1>
            <hr />

            <div className={classes.mainContent}>
                {fileState === 'initial' && (
                    <div className={classes.uploadSection}>
                        <InputFile handleFile={handleFile}/>
                        <NoInfo text={'Sube un archivo para comenzar'}/>
                    </div>
                )}

                {fileState === 'uploaded' && (
                    <div className={classes.formSection}>
                        <div className={classes.expedienteSelect}>
                            <h3>Selecciona un expediente</h3>
                            <Select 
                                className={classes.selectDG}
                                onChange={handleSelectExpediente} 
                                options={optionsExpediente}
                                classNames={customClassNames}
                                theme={customSelectTheme}
                            />
                        </div>

                        {selectedExpediente && (
                            <div className={classes.clientsSection}>
                                {extractedTexts.map((text) => (
                                    <div className={classes.clientSelect} key={text}>
                                        <p>Selecciona a <b>{text}</b></p>
                                        <Select 
                                            className={classes.selectDG}
                                            value={optionsClientes.find(
                                                (option) => option.value === selectedClient[text]
                                            )}
                                            onChange={(choice) => handleSelectClient(choice, text)} 
                                            options={optionsClientes}
                                            classNames={customClassNames}
                                            theme={customSelectTheme}
                                            placeholder="Selecciona un cliente..."
                                            isSearchable
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className={classes.actionButtons}>
                            <Button 
                                className={classes.resetButton}
                                action={() => {
                                    setFileState('initial');
                                    setFileExtracted(undefined);
                                    setExtractedTexts([]);
                                    setSelectedExpediente(undefined);
                                    setSelectedClient({});
                                    setSelectedClientIDs({});
                                }}
                            >
                                Cancelar y subir otro archivo
                            </Button>

                            {selectedExpediente && 
                             extractedTexts.length > 0 && 
                             extractedTexts.every(tag => selectedClient[tag]) && (
                                <Button 
                                    className={classes.processButton}
                                    action={handleSendingFormat}
                                >
                                    Procesar formato
                                </Button>
                            )}
                        </div>
                    </div>
                )}

                {download && (
                    <div className={classes.downloadSection}>
                        <p>¡Tu archivo está listo!</p>
                        <a href={download} download={'formato'}>
                            Descargar formato
                        </a>
                    </div>
                )}

                <Button 
                    className={classes.tagGeneratorButton}
                    action={() => setIsTagGeneratorOpen(true)}
                    style={{
                        position: 'fixed',
                        bottom: '30px',
                        right: '30px',
                        background: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                        padding: '0px 10px'
                    }}
                >
                    <span style={{display: 'block'}}>Etiquetas</span>

                    <svg 
                        width="20" 
                        height="20" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    >
                        <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"/>
                        <line x1="7" y1="7" x2="7.01" y2="7"/>
                    </svg>
                </Button>

                <Dialog 
                    isOpen={isTagGeneratorOpen}
                    onClose={() => setIsTagGeneratorOpen(false)}
                    title="Generador de etiquetas"
                >
                    <TagGenerator 
                        tagConfig={tagConfig}
                        setTagConfig={setTagConfig}
                    />
                </Dialog>
            </div>

            {
                loading && <div className={classes.loaderContainer}>
                    <Loader />
                </div>
            }
        </div>
    );
};
import React, { useState } from 'react';
import Input from './Input';
import Select from 'react-select';
import Button from './Button';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import classes from './TagGenerator.module.css';
import Tooltip from './Tooltip';

const TEMPLATE_TAGS = {
    CLIENT: [
        { label: 'Nombre Completo', value: '.nombre', icon: '👤' },
        { label: 'Datos Generales', value: '.datos', icon: '📋' },
        { label: 'CURP', value: '.curp', icon: '🆔' },
        { label: 'RFC', value: '.rfc', icon: '💼' },
        { label: 'Domicilio', value: '.domicilio', icon: '🏠' },
        { label: 'Edad', value: '.edad', icon: '📅' },
        { label: 'Nacionalidad', value: '.nacionalidad', icon: '🌎' },
        { label: 'Estado Civil', value: '.estado_civil', icon: '💑' },
        { label: 'Profesión', value: '.profesion', icon: '👔' },
        { label: 'Ocupación', value: '.ocupacion', icon: '💪' },
        { label: 'INE', value: '.ine', icon: '📄' }
    ]
};

const TagGenerator = ({ tagConfig, setTagConfig }) => {
    const [copiedTag, setCopiedTag] = useState(null);

    const copyToClipboard = async (text, tagId) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopiedTag(tagId);
            setTimeout(() => setCopiedTag(null), 1500);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const generateTags = () => {
        const { prefix, quantity, selectedTag } = tagConfig;
        if (!prefix || !selectedTag) return [];

        const selectedTagInfo = TEMPLATE_TAGS.CLIENT.find(tag => tag.value === selectedTag);
        
        return Array.from({ length: quantity }, (_, i) => ({
            id: `${prefix}${i + 1}${selectedTag}`,
            label: `${prefix}${i + 1}${selectedTag}`,
            value: `{{ ${prefix}${i + 1}${selectedTag} }}`,
            icon: selectedTagInfo?.icon
        }));
    };

    return (
        <div className={classes.container}>
            <div className={classes.inputSection}>
                <div className={classes.inputGroup}>
                    <Input 
                        label="Prefijo" 
                        name="prefix"
                        type="text"
                        value={tagConfig.prefix}
                        onChange={(e) => setTagConfig(prev => ({
                            ...prev,
                            prefix: e.target.value
                        }))}
                        placeholder="ej: vendedor, comprador"
                    />
                    <Tooltip text="El prefijo se usará para generar las etiquetas (ej: vendedor1, comprador2)">
                        <span className={classes.tooltipIcon}>?</span>
                    </Tooltip>
                </div>

                <div className={classes.inputGroup}>
                    <Input 
                        label="Cantidad" 
                        name="quantity"
                        type="number"
                        min="1"
                        max="10"
                        value={tagConfig.quantity}
                        onChange={(e) => setTagConfig(prev => ({
                            ...prev,
                            quantity: Math.min(Math.max(1, parseInt(e.target.value) || 1), 10)
                        }))}
                    />
                </div>

                <div className={classes.selectGroup}>
                    <Select
                        className={classes.select}
                        placeholder="Selecciona el tipo de dato"
                        options={TEMPLATE_TAGS.CLIENT.map(tag => ({
                            ...tag,
                            label: `${tag.icon} ${tag.label}`
                        }))}
                        onChange={(option) => setTagConfig(prev => ({ 
                            ...prev, 
                            selectedTag: option.value 
                        }))}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                    />
                </div>
            </div>

            <div className={classes.preview}>
                <h3>Vista previa</h3>
                <div className={classes.tagsContainer}>
                    {generateTags().map(tag => (
                        <button 
                            key={tag.id}
                            onClick={() => copyToClipboard(tag.value, tag.id)}
                            className={`${classes.tagButton} ${copiedTag === tag.id ? classes.copied : ''}`}
                        >
                            <span className={classes.tagIcon}>{tag.icon}</span>
                            <span className={classes.tagLabel}>{tag.label}</span>
                            <span className={classes.copyIndicator}>
                                {copiedTag === tag.id ? '¡Copiado!' : 'Copiar'}
                            </span>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TagGenerator; 
import React from 'react';
import classes from './Dialog.module.css';

const Dialog = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className={classes.dialogOverlay}>
            <div className={classes.dialogContent}>
                <div className={classes.dialogHeader}>
                    <h2>{title}</h2>
                    <button 
                        onClick={onClose}
                        className={classes.closeButton}
                    >
                        ×
                    </button>
                </div>
                <div className={classes.dialogBody}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Dialog; 